<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-card
        v-if="verified"
        class="elevation-12 mx-auto my-10"
        max-width="40%"
      >
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Registration Complete</v-toolbar-title>
        </v-toolbar>
        <p class="font-weight-regular ml-5 mt-5 mb-5 mr-5">
          Thank you for registering in the Mars Wrigley Theater Operator
          Distribution Program.
        </p>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import api from "@/services/api";

export default {
  data() {
    return {
      verified: false,
      error: false
    };
  },

  mounted() {
    const regCode = this.$route.params.code;

    api
      .updateContractData(regCode, {
        verified: true,
        registration_code: regCode
      })
      .then(() => {
        this.verified = true;
      })
      .catch(() => {
        this.error = true;
      });
  }
};
</script>

<style></style>
